import React from "react";
import "@fortawesome/react-fontawesome";

export const Contact = (props) => {
  return (
    <div>
      <div id="contact">
        <div className="section-title contact-section-title">
          <h2>Unde ne găsești</h2>
        </div>
        <div className="container">
          <div className="col-md-7">
            <div className="row">
              <div class="embed-responsive embed-responsive-4by3">
                <div id="map-container" class="embed-responsive-item">
                  <div id="map">
                    <iframe
                      title="Google Maps location"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d697.2862481764021!2d25.597685869715523!3d45.64790993571261!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b35b01a04500b1%3A0x38826ad636c07505!2sVinoteca%20Terre%20di%20Verona!5e0!3m2!1sro!2sro!4v1726410938806!5m2!1sro!2sro"
                      width="600"
                      height="450"
                      allowfullscreen="false"
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3 style={{marginTop:0}}>Informații de contact</h3>
              <p>
                <span>
                  <i className="fa fa-location-dot"></i> Adresă
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Telefon
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-clock"></i> Program
                </span>{" "}
                {props.data ? props.data.scheduleDays : "loading"}
              </p>
              <p> {props.data ? props.data.scheduleHours : "loading"}</p>
            </div>

            {/* <div className="contact-item-sm">
              <p>
                <span>
                  <a href="https://www.facebook.com/">
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                </span>
                <span>
                  <a href="https://wa.me/075172481/">
                    <i className="fa-brands fa-whatsapp"></i>
                  </a>
                </span>
              </p>
            </div> */}
          </div>
          {/* <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa-brands fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa-brands fa-whatsapp"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
        {/* WHATSAPP CHAT */}
        <span className="whatsapp_chat">
          <a
            href="https://wa.me/+40759881176"
            class="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="fa-brands fa-whatsapp"></i>
          </a>
        </span>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy;2024 Vinoteca Terre di Verona</p>
        </div>
      </div>
    </div>
  );
};
